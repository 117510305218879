// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"gridSize": "16",
	"GalleryItem_hoverScale": "2",
	"xl": "1600",
	"lg": "1280",
	"md": "960",
	"sm": "640"
};
module.exports = exports;
